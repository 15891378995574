.floorplans-dashboard-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 2rem;
    margin-right: 1rem;
    /* font-family: Helvetica; */
}

.floorplans-dashboard-header__title {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    color: var(--text-color);
}

.floorplans-dashboard-header__actions .p-button {
    padding: 0.5rem 1rem;
    margin-left: 1rem;
}

.floorplans-dashboard-breadcrumb {
    margin-left: 2rem;
    margin-right: 1rem;
    max-width: 657px;
}

.floorplans-dashboard-template-list {
    display: flex;
    flex-direction: row;
    margin-left: 2rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
    /* font-family: Helvetica; */
    flex-wrap: wrap;
}

.floorplans-dashboard-template-list__item {
    margin-right: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
}

.floorplans-dashboard-template-list__item-card {
    display: grid;
    height: 240px;
    width: 320px;
    border-radius: 9px;
    border: 1px solid #D9D9D9;
    grid-template-columns: 3fr 2.2fr 2.2fr;
    grid-template-rows: 0.5fr 1fr 1fr 1fr 1fr;
    overflow: hidden;
}

.floorplans-dashboard-template-list__item-card:hover {
    scale: 1.01;
}

.floorplans-dashboard-template-list__item__main-image {
    grid-column: 1 / 2;
    grid-row: 1 / -1;
    border-radius: 9px 0 0 9px;
    padding: 5px;
    position: relative;
}
.floorplans-dashboard-template-list__item__main-image__hover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.40);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.1rem;
    font-weight: 500;
}
.floorplans-dashboard-template-list__item-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.floorplans-dashboard-template-list__item-card sup {
    font-size: 0.5rem;
    vertical-align: super;
}
.floorplans-dashboard-template-list__item__top-row {
    grid-column: 2 / -1;
    grid-row: 1 / 2;
    display: flex;
    justify-content: flex-end;
}
.floorplans-dashboard-template-list__item__top-row .p-button {
    padding: 0 1rem;
}

.floorplans-dashboard-template-list__item__bottom-right-corner {
    border-radius: 0 0 9px 0;
}

.floorplans-dashboard-template-list__item__info-left,
.floorplans-dashboard-template-list__item__info-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-bottom: #D9D9D9 1px solid;
    color: #9FA9B7;
}

.floorplans-dashboard-template-list__item__info-right {
    border-left: #D9D9D9 1px solid;
}

.floorplans-dashboard-template-list__item__info__sqft-text {
    font-size: 0.8rem;
}

.floorplans-dashboard-template-list__item-name {
    margin-top: 0.75rem;
    margin-bottom: 0.35rem;
    margin-left: 0.5rem;
}

.floorplans-dashboard-template-list__item-subtitle {
    color: var(--text-color);
    font-size: 0.8rem;
    margin-left: 0.5rem;
}

.customers-dashboard-tabview {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0.75rem;
}

.customers-dashboard-tabview .p-tabview-nav-link {
    padding: 0.75rem 1.25rem !important;
}

.customers-dashboard-tab-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    /* font-family: Helvetica; */
}

.customers-dashboard-tab-header__title {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    color: var(--text-color);
}

.customers-dashboard-tab-header__subtitle {
    color: #8896A5;
    font-size: 0.8rem;    
}

.customers-dashboard-tab-header__actions .p-button {
    padding: 0.5rem 1rem;
    margin-left: 0.7rem;
    font-size: 0.8rem;
    font-weight: 600;
}
.customers-dashboard-tab-header__actions .p-button-icon {
    font-size: 0.95rem;
}

.customers-dashboard-tab-content {
    display: flex;
    flex-direction: row;
}

.customers-dashboard-tab-content__contact-list,
.customers-dashboard-tab-content__contact-view {
    border-radius: 10px;
    border: 1.5px solid #D9D9D9;
}

.customers-dashboard-tab-content__contact-list {
    width: 430px;
    height: 600px;
    margin-right: 1rem;
}

.customers-dashboard-tab-content__contact-list__search-bar {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid #D9D9D9;;
}

.customers-dashboard-tab-content__contact-list__search-bar .p-input-icon-left {
    width: 100%;
}

.customers-dashboard-tab-content__contact-list__search-bar .p-input-icon-left .p-inputtext {
    width: 100%;
    font-size: 0.9rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background: rgba(217, 217, 217, 0.29);
    border: 0;
}

.customers-dashboard-tab-content__contact-list__list-item {
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    padding-left: 1.5rem;
    padding-top: 0.5rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    position: relative;
}

.customers-dashboard-tab-content__contact-list__list-item.selected {
    background-color: #EDF7EF;
}

.customers-dashboard-tab-content__contact-list__list-item__contact-name {
    color: var(--text-color);
    font-size: 1.1rem;
    font-weight: 600;
}

.customers-dashboard-tab-content__contact-list__list-item__selected-plans {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--text-color);
    font-size: 1rem;
    font-weight: 500;
}

.customers-dashboard-tab-content__contact-list__list-item__selected-plans__icon {
    margin-right: 0.5rem
}

.customers-dashboard-tab-content__contact-list__list-item__date {
    position: absolute;
    color: #8896A5;
    font-size: 0.8rem;
    top: 0.5rem;
    right: 1rem;
}

.customers-dashboard-tab-content__contact-list__list-item__message-preview {
    color: #8896A5;
    font-size: 0.75rem;
    white-space: nowrap; /* keep text on a single line */
    overflow: hidden; /* hide any text that overflows the element */
    text-overflow: ellipsis;
}

.customers-dashboard-tab-content__contact-list__list-item .p-badge {
    position: absolute;
    top: 1rem;
    left: 0.6rem;
}

.customers-dashboard-tab-content__contact-list__list-item__right-arrow {
    position: absolute;    
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    color: var(--text-color)
}

.customers-dashboard-tab-content__contact-view {
    flex: 1;
    padding-left: 1.5rem;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}

.customers-dashboard-tab-content__contact-view__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.customers-dashboard-tab-content__contact-view__name {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.customers-dashboard-tab-content__contact-view__subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #8896A5;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
}

.customers-dashboard-tab-content__contact-view__subtitle i,
.customers-dashboard-tab-content__contact-view__subtitle .p-badge {
    margin-right: 0.5rem;
    padding-top: 0.2rem;
    align-content: center;
}

.customers-dashboard-tab-content__contact-view__header-right {
    display: flex;
    flex-direction: column;
}

.customers-dashboard-tab-content__contact-view__header-right .p-button-label {
    text-align: start;
}

.customers-dashboard-tab-content__contact-view__header-right .p-button {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 0.3rem;
}

.customers-dashboard-tab-content__contact-view__message-section {
    display: flex;
    flex-direction: column;
    border-bottom: #D9D9D9 1px dashed;
}

.customers-dashboard-tab-content__contact-view__message-section__header,
.customers-dashboard-tab-content__contact-view__floorplans-section__header {
    color: var(--text-color);
    margin-bottom: 0.5rem;
}

.customers-dashboard-tab-content__contact-view__message-section__message {
    border-radius: 5px;
    background: rgba(217, 217, 217, 0.13);
    padding: 1rem;
    color: #8896A5;
    font-size: 0.9rem;
}

.customers-dashboard-tab-content__contact-view__message-section__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.7rem;
    margin-bottom: 1rem;
}

.customers-dashboard-tab-content__contact-view__message-section__buttons .p-button {
    padding: 0.3rem 0.6rem;
    font-size: 0.7rem;
    margin-left: 0.6rem;
}

.customers-dashboard-tab-content__contact-view__message-section__buttons .p-button-icon {
    font-size: 0.8rem;
}

.floorplan-template-content {
    margin-left: 2rem;
    margin-right: 1rem;
}

.floorplan-template-content__title {
    color: var(--text-color);
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 2rem;
    margin-bottom: 0;
}

.floorplan-template-content__subtitle {
    color: #8896A5;
    font-size: 0.8rem;
    margin-top: 0.4rem;
}

.floorplan-template-content__preview {
    margin-top: 1.5rem;
    height: 600px;
}
.floorplan-template-content__preview iframe {
    overflow: hidden;
}

.floorplan-template-content__search-filter-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.floorplan-template-content__search-filter-bar .p-input-icon-left {
    width: min(378px, 60%)
}
.floorplan-template-content__search-filter-bar .p-inputtext {
    font-size: 0.9rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background: rgba(217, 217, 217, 0.29);
    border-color: #64748B;
    width: 100%
}
.floorplan-template-content__search-filter-bar .p-button {
    margin-left: 1rem;
    font-size: 0.9rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.floorplan-template-content__customers-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.floorplan-template-content__customers-list__customer {
    width: 280px;
    padding-left: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    position: relative;
    margin: 0.5rem;
}
.floorplan-template-content__customers-list__customer__date {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    color: #8896A5;
    font-size: 0.8rem;
}
.floorplan-template-content__customers-list__customer .p-badge {
    position: absolute;
    top: 0.75rem;
    left: 0.7rem;
}
.floorplan-template-content__customers-list__customer__name {
    color: var(--text-color);
    font-size: 1.05rem;
    font-weight: 600;
    margin-bottom: 0.3rem
}
.floorplan-template-content__customers-list__customer__plans {
    color: #8896A5;
    font-size: 0.95rem;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.floorplan-template-content__customers-list__customer__plans__icon {
    margin-right: 0.5rem
}