.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    height: 63px;
    padding: 5px 20px;
    border-bottom: 1px solid #DEE2E6;
}
  
.navbar__left {
    display: flex;
    align-items: center;
}

.navbar__left__routes {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: 2.5rem;
    font: 1em sans-serif;
}

.navbar-route-option {
    color: #8896A5;
    text-decoration: none;
}

.navbar-route-option.active {
    color: #495057;
}
  
.navbar__left img {
    height: 20px;
}

.navbar .p-button.p-button:focus { 
    outline: none;
    box-shadow: none;
}

/* .p-button:focus { 
    outline: none;
    box-shadow: none;
} */

/* .navbar__right {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    cursor: pointer;
} */
   