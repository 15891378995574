/* App.css */
.App {
  width: 100%;
  min-height: 100vh; /* use min-height to avoid exceeding the viewport height */
  margin: 0;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


html, body {
  height: 100%;
  margin: 0;
}
#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.iframe-container iframe {
  width: 100vw;
  height: 100vh;
  border: none; /* Optional: removes the border around the iframe */
}


.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000; /* Ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-overlay video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will ensure the video covers the whole screen */
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App button {
  margin-top: 20px;
}