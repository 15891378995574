.floorplan-card-horizontal {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.floorplan-card-horizontal.restricted-width {
    min-width: 450px;
    max-width: 450px;
    flex: 1;
}

.floorplan-card-horizontal:hover {
    background-color: var(--surface-hover);
}

.floorplan-card-horizontal:active {
    background-color: rgba(100, 116, 139, 0.16);;
}

.floorplan-card-horizontal__image {
    width: 184px;
    height: 125px;
    object-fit: cover;
}
.floorplan-card-horizontal__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.floorplan-card-horizontal__content {
    flex: 1;
    position: relative;
    padding: 1rem;
}
.floorplan-card-horizontal__title {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 1rem;
    color: var(--text-color)
}
.floorplan-card-horizontal__subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #9FA9B7;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
}
.floorplan-card-horizontal__subtitle-highlighted {
    color: var(--text-color);
    font-weight: 500;
}
.floorplan-card-horizontal__corner-buttons {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
}
.floorplan-card-horizontal__corner-buttons .p-button {
    padding: 0.2rem 0.5rem;
}
