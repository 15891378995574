.customized-floorplan-details-dialog__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}

.customized-floorplan-details-dialog__top__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.customized-floorplan-details-dialog__top .p-button {
    padding: 0.5rem 0.7rem;
    margin-left: 0.5rem;
    font-size: 0.8rem;
    font-weight: 600;
}
.customized-floorplan-details-dialog__top .p-button-icon {
    font-size: 0.95rem;
}

.customized-floorplan-details-dialog__body {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.customized-floorplan-details-dialog__body__left {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
}

.customized-floorplan-details-dialog__body__right {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1.5;
}

.customized-floorplan-details-dialog__panel {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 2px solid #D9D9D9;
}

.customized-floorplan-details-dialog__panel__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 1rem;
    border-bottom: 2px solid #D9D9D9;
    font-weight: 600;
    font-size: 0.9rem;
    background-color: rgba(217, 217, 217, 0.29);
}

.customized-floorplan-details-dialog__panel__body {
    display: flex;
    flex-direction: column;
}

.customized-floorplan-details-dialog__panel__body__list-item {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #D9D9D9;
}

.customized-floorplan-details-dialog__panel__body__list-item.end {
    border-bottom: none;
}

.customized-floorplan-details-dialog__panel__body__list-item__detail-text {
    font-size: 0.8rem;
    color: #9FA9B7;
}

.customized-floorplan-details-dialog__panel__body__list-item__detail-text .purple-text {
    color: #9351E7;
}

.customized-floorplan-details-dialog__panel__body__floorplan-img {
    display: flex;
    width: 100%;
    position: relative;
}

.customized-floorplan-details-dialog__panel__body__floorplan-img::after {
    content: '';
    display: block;
    padding-top: 70%; /* Maintains the 7:10 aspect ratio */
}

.customized-floorplan-details-dialog__panel__body__relative-location{
    position: relative;
}

.customized-floorplan-details-dialog__panel__body__floorplan-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.customized-floorplan-details-dialog__panel__body__chat-history {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem 0.5rem;
    overflow-y: scroll;
}

.customized-floorplan-details-dialog__panel__body__chat-history__msg {
    padding: 0.5rem 0.75rem;
    border-radius: 10px;
    background-color: rgba(217, 217, 217, 0.29);
}

.customized-floorplan-details-dialog__panel__body__chat-history__msg.self-user {
    margin-left: 20%;
}

.customized-floorplan-details-dialog__panel__body__chat-history__msg.other-user {
    margin-right: 20%;
    background-color: rgba(147, 81, 231, 0.13);
}

.general-choices-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.general-choice-title {
    margin: 0;
    color: var(--text-color);
}
.general-choice-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
}