.agent-floorplan-page__cover-photo {
    margin-top: 1.5rem;
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
}
.agent-floorplan-page__cover-photo img {
    width: 80%;
    height: 100%;
    object-fit: cover;
}