.firebase-auth-container .mdl-card {
    all:unset !important;
}

.firebaseui-button {
    border-radius: 10px !important;
    box-shadow:unset !important;
    text-transform: none !important;
}

.firebaseui-button.firebaseui-id-submit {
    background-color: #3B82F6 !important;
    color: #fff !important;
}

.firebaseui-button.firebaseui-id-submit:hover {
    background-color: rgb(59, 130, 246, 0.9) !important;    
    color: #fff !important;
}

.firebaseui-button.firebaseui-id-secondary-link {
    color: var(--text-color-secondary) !important;
}

/* .firebase-auth-container .mdl-textfield {
    all:unset !important;
}

.firebase-auth-container .mdl-textfield__label {
    all:unset !important;
} */

/* .firebase-auth-container .mdl-textfield--floating-label {
    all:unset !important;
}

.is-upgraded {
    all:unset;
}

.is-focused {
    all:unset;
} */