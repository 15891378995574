.floorplan-card-grid-box {
    display: flex;
    flex-direction: column;
}

.floorplan-card-grid-box__card {
    display:flex;
    flex-direction: column;
    width: 300px;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    overflow: hidden;
    cursor: pointer;
}

.floorplan-card-grid-box__card:hover {
    scale: 1.02;
}

/* .floorplan-card-grid-box__card:active {
    scale: 0.98;
} */

.floorplan-card-grid-box__card__thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.floorplan-card-grid-box__card__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.floorplan-card-grid-box__card__bottom__left {
    display: flex;
    flex-direction: row;
    padding: 0.6rem 1rem 0.5rem 1rem;
    gap: 0.5rem;
}

.floorplan-card-grid-box__card__bottom__left__info-item {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    color: #9FA9B7;
}

.floorplan-card-grid-box__card__bottom__right {
    padding: 0.6rem 1rem 0.5rem 1rem;
    border-left: 1px solid #D9D9D9;
    align-items: center;
    justify-content: center;
    color: #9FA9B7;
    cursor: pointer;
}

.floorplan-card-grid-box__card__bottom__right:hover {
    color: #495057;
    scale: 1.02;
}

.floorplan-card-grid-box__name {
    margin-top: 0.55rem;
    margin-bottom: 0rem;
    margin-left: 0.5rem;
    color: var(--text-color);
}

.floorplan-card-grid-box__subtitle {
    color: #9FA9B7;
    font-size: 0.8rem;
    margin-left: 0.5rem;
    margin-top: 0.35rem;
}